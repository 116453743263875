<template>
  <span>
    {{ x('statisticsFor') }} {{config.obj_texts.AppName}}
    <a @click="showDashboardOptions()" class='changeDate'>
      <i class='fa fa-calendar'></i>
      <span class='HeadlineDetails'>({{fromDateAsString}} - {{toDateAsString}})</span>
    </a>
  </span>
</template>

<script>
  import { mapState } from 'vuex';
  import moment from 'moment';
  import translate from '../Mixins/Translate';

  export default {
    name: 'dashboard-date-range',
    mixins: [translate],
    computed: {
      fromDateAsString() {
        const { days } = this.$store.state.dashboardOptions;
        const then = moment().subtract(days, 'days');
        return `${then.format('D')} ${this.monthArray[then.month()]}`;
      },
      toDateAsString() {
        const today = moment();
        return `${today.format('D')} ${this.monthArray[today.month()]}`;
      },
      monthArray() {
        return [
          this.x('shortJanuary'),
          this.x('shortFebruary'),
          this.x('shortMarch'),
          this.x('shortApril'),
          this.x('shortMay'),
          this.x('shortJune'),
          this.x('shortJuly'),
          this.x('shortAugust'),
          this.x('shortSeptember'),
          this.x('shortOctober'),
          this.x('shortNovember'),
          this.x('shortDecember'),
        ];
      },
      ...mapState(['config', 'user', 'dashboardOptions']),
    },
    methods: {
      showDashboardOptions() {
        this.$store.commit('setDashboardVisible', true);
      },
    },
  };
</script>
